import React from 'react';
import styled from 'styled-components';
import Logo from '../assets/images/logo.svg';

const Container = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
`;

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 0;
`;

const Msg = styled.h1`
  font-size: 21px;
  font-weight: 400;
  margin: 0;
  padding-top: 48px;
  text-align: center;
`;

const HomePage = () => {
  return (
    <>
      <Container>
        <Hero>
          <img src={Logo} alt="logo" width="450" />
          <Msg>We plan, design, develop and deliver Results.</Msg>
        </Hero>
      </Container>
    </>
  );
};

export default HomePage;
