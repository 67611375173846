import React from 'react';
import styled from 'styled-components';

const Header = styled.header`
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #0f295d;
`;

const AppHeader = () => {
  return <Header />;
};

export default AppHeader;
