import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { GlobalStyles } from '../components/Themes/GlobalStyles';
import AppHeader from '../components/AppHeader';

const Wrapper = styled.section`
  padding: 24px;
`;

const AdminLayout = () => {
  return (
    <>
      <GlobalStyles />
      <div>
        <AppHeader />
        <Wrapper>
          <Outlet />
        </Wrapper>
      </div>
    </>
  );
};

export default AdminLayout;
