import { Route, Routes } from 'react-router-dom';

import DefaultLayout from '../layout/DefaultLayout';
import AuthLayout from '../layout/AuthLayout';
import AdminLayout from '../layout/AdminLayout';

import HomePage from '../pages/home';
import AboutPage from '../pages/about';
import LoginPage from '../pages/login';

const RenderRoutes = () => {
  return (
    <Routes>
      <Route element={<AdminLayout />}>
        <Route path="/" element={<HomePage />} />
      </Route>

      <Route element={<AuthLayout />}>
        <Route path="/login" element={<LoginPage />} />
      </Route>

      <Route element={<DefaultLayout />}>
        <Route path="/about" element={<AboutPage />} />
      </Route>
    </Routes>
  );
};

export default RenderRoutes;
