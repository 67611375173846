import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    background: #001d52;
    margin: 0;
    color: #fff;
    font-family: "Avenir Next","Lato","Helvetica Neue",Arial,sans-serif;;
    transition: all 0.125s linear;
  }
  img {
    max-width: 100%;
    height: auto;
  }
`;
